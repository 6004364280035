import * as React from 'react';

import {
    HTMLMotionProps, motion 
} from 'framer-motion';

import {
    HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE 
} from '../../../styles/global-style-constants';
import { isBrowser } from '../../../utils/url-utils';

export const CustomSection: React.FC<React.PropsWithChildren<HTMLMotionProps<'section'>>> = (
    props: React.PropsWithChildren<HTMLMotionProps<'section'>>
) => {
    const headerHeight = isBrowser() ?
        (window.innerWidth > 480 ? HEADER_HEIGHT_DESKTOP : HEADER_HEIGHT_MOBILE)
        : HEADER_HEIGHT_DESKTOP;
    return (
        <motion.section
            {...props}
            viewport={{ margin: `0px 0px -${headerHeight}px 0px` }}
        >
            {props.children}
        </motion.section>
    );
};