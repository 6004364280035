// extracted by mini-css-extract-plugin
export var cta = "card-item-module--cta--ada28";
export var ctaContainer = "card-item-module--ctaContainer--9817a";
export var intImg = "card-item-module--intImg--5f450";
export var intImgContainer = "card-item-module--intImgContainer--59601";
export var intItem = "card-item-module--intItem--96619";
export var intSep = "card-item-module--intSep--3b999";
export var intTitle = "card-item-module--intTitle--39e94";
export var intsBlock = "card-item-module--intsBlock--3f884";
export var skillBtnContainer = "card-item-module--skillBtnContainer--fd8f0";
export var skillImg = "card-item-module--skillImg--26707";
export var skillImgContainer = "card-item-module--skillImgContainer--51b0c";
export var skillItem = "card-item-module--skillItem--f5899";
export var skillItemTitle = "card-item-module--skillItemTitle--06536";
export var skillMotionDesign = "card-item-module--skillMotionDesign--f9d5b";
export var skillTxtContainer = "card-item-module--skillTxtContainer--182f5";
export var skillsBlock = "card-item-module--skillsBlock--781bd";
export var skillsContainer = "card-item-module--skillsContainer--872f6";
export var tag = "card-item-module--tag--ecbbb";
export var titleBlock = "card-item-module--titleBlock--b6f89";
export var upper = "card-item-module--upper--765b7";
export var upperLeft = "card-item-module--upperLeft--4e3e0";
export var upperRight = "card-item-module--upperRight--26b3b";
export var withAntimation = "card-item-module--withAntimation--c905d";