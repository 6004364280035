import * as React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import {
    buildGatsbyStrapiImage,
    isUndOrEmpty
} from '../../../utils';
import { SeeMoreButton } from '../see-more-button/see-more-button.component';
import * as styles from './card-item.module.css';
import { CardItemProps } from './card-item.types';

export const CardItem: React.FC<CardItemProps> = ({
    expertise, withAntimation = false, withCtaLink = false
}: CardItemProps) => {

    const [isHover, setIsHover] = React.useState(false);

    const img = expertise.picture ? buildGatsbyStrapiImage(expertise.picture) : undefined;
    const imgHover = expertise.pictureHover ? buildGatsbyStrapiImage(expertise.pictureHover) : undefined;

    const borderColor: string = '#1DBACF';
    const borderColorHover: string = '#E3019D';


    return (
        <div className={`w-100 h-100 p-3 ${styles.intItem} ${withAntimation ? `${styles.withAntimation}` : ''}`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}>
            <div className='d-flex align-items-center pt-3'>
                <div className={`px-3 ${styles.intTitle}`}>
                    {!isUndOrEmpty(expertise.title) && (
                        <div style={
                            {
                                paddingBottom: '0.5rem',
                                borderBottom: `4px solid ${isHover ? borderColorHover : borderColor}`
                            }
                        }>
                            <h3>{expertise.title}</h3>
                        </div>
                    )}
                </div>
                <div className={styles.intSep}></div>

                <div className={styles.intImgContainer}>
                    {
                        imgHover && isHover
                            ? (imgHover && <GatsbyImage
                                className={styles.intImg}
                                image={imgHover}
                                objectFit='contain'
                                alt=""
                            />)
                            : (img && <GatsbyImage
                                className={styles.intImg}
                                image={img}
                                objectFit='contain'
                                alt=""
                            />
                            )
                    }
                </div>
            </div>

            {
                expertise.tags && expertise.tags.length ? <div className='d-flex m-2 justify-content-start'>
                    {
                        expertise.tags.map((tag: string, index: number) => {
                            return (<div key={`${tag}_${index}`}>
                                <span className={`mr-3 ${styles.tag}`}
                                    style={
                                        {
                                            color: `${isHover ? borderColorHover : borderColor}`,
                                            borderColor: `${isHover ? borderColorHover : borderColor}`
                                        }
                                    }>
                                    {`#${tag}`}
                                </span>
                            </div>);
                        })
                    }
                </div> : null
            }

            <div className="col-12 col-lg-9 text-left pb-5 px-3" style={{ lineHeight: '22px' }}>
                {!isUndOrEmpty(expertise.description) &&
                    <ReactMarkdown>{expertise.description}</ReactMarkdown>}
            </div>
            {withCtaLink && (
                <div className={`px-3 ${styles.ctaContainer}`}>
                    <SeeMoreButton
                        color={isHover ? borderColorHover : borderColor}
                        link={expertise.ctaLink}
                        isBlank={expertise.isExternalLink}
                        text={expertise.ctaText}>
                    </SeeMoreButton>
                </div>
            )}
        </div>
    );
};