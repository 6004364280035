import * as React from 'react';

import { motion } from 'framer-motion';

import scrollImg from '../../../assets/images/scroll.png';
import * as styles from './banner-block.module.css';

interface BannerBlockProps {
    title: string;
    subtitle: string;
    backgroundClassName: string;
    // images: GatsbyStrapiPictureField;
    onScrollIconClick: () => void;
}

export const BannerBlock: React.FC<BannerBlockProps> = ({
    title, subtitle, backgroundClassName, onScrollIconClick,
}) => {
    return (
        <React.Fragment>
            <div className={`w-100 ${styles.bannerRoot} ${styles[`titleContainer${backgroundClassName}`]}`}>
                <div
                    className={`${styles.titleContainer}`}>
                    <h2 className={`display-1 ${styles.title}`}>{title}</h2>
                    <p className={`display-7 mx-1 mx-xl-3 mt-5 ${styles.subtitle}`}>{subtitle}</p>
                </div>
                <motion.div
                    className={`mb-3 ${styles.scrollImg}`}
                    onTap={onScrollIconClick}
                >
                    <motion.img
                        src={scrollImg}
                        className='h-100 w-100'
                        animate={{ y: -20 }}
                        transition={{
                            ease: 'easeOut',
                            duration: 0.6,
                            repeat: Infinity,
                            repeatType: 'loop'
                        }}
                    />
                </motion.div>
            </div>
        </React.Fragment>
    );
};