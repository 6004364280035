import * as React from 'react';

import { GatsbyStrapiPictureField } from '../../../models/cms-common';
import { isUndOrEmpty } from '../../../utils/string-utils';
import { CardItem } from '../../shared/card-item/card-item.component';
import { TitleBlock } from '../../shared/title-block/title-block.component';

interface NosExpertisesBlockProps {
    id?: string;
    title?: string;
    subtitle?: string;
    expertiseDomains: {
        title: string;
        description: string;
        picture: GatsbyStrapiPictureField;
        pictureHover: GatsbyStrapiPictureField;
        ctaText: string;
        ctaLink: string;
    }[];
    withGreenCircle?: boolean;
}

export const NosExpertisesBlock: React.FC<NosExpertisesBlockProps> = ({
    id,
    title,
    subtitle,
    expertiseDomains,
}: NosExpertisesBlockProps) => {
    return (
        <React.Fragment>
            <div id={id} className="mt-5">
                {!isUndOrEmpty(title) && <TitleBlock withGreenCircle={true}>{title}</TitleBlock>}

                {!isUndOrEmpty(subtitle) && <p>{subtitle}</p>}

                <div className="my-5">
                    <div className="row justify-content-center">
                        {expertiseDomains.map((exp, index) => {
                            const withLink = exp.ctaLink !== undefined && exp.ctaLink !== null && exp.ctaLink !== '';
                            return (
                                <div
                                    key={`${exp.title}-${index}`}
                                    className="col-12 col-md-6 col-lg-4 col-xl-3 my-3 align-self-stretch"
                                >
                                    <CardItem
                                        withAntimation={true}
                                        withCtaLink={withLink}
                                        expertise={exp}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
