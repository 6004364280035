import * as React from 'react';

import ReactMarkdown from 'react-markdown';
import Carousel from 'react-multi-carousel';

import { GatsbyStrapiPictureField } from '../../../models/cms-common';
import { isUndOrEmpty } from '../../../utils';
import { CardItem } from '../card-item/card-item.component';
import { CardItemProps } from '../card-item/card-item.types';
import { TitleBlock } from '../title-block/title-block.component';
import * as styles from './blog-posts-carousel.module.css';

const responsive = {
    md: {
        breakpoint: {
            max: 3000,
            min: 993,
        },
        items: 3,
    },
    sm: {
        breakpoint: {
            max: 992,
            min: 769,
        },
        items: 2,
    },
    xs: {
        breakpoint: {
            max: 768,
            min: 0,
        },
        items: 1,
    },
};

interface BlogPostsCarouselProps {
    title?: string;
    subtitle?: string;
    posts: {
        id: string;
        slug: string;
        title: string;
        subtitle: string;
        bannerPicture: GatsbyStrapiPictureField;
        category: {
            id: string;
            slug: string;
        };
        tags: {
            id: string;
            slug: string;
        }[];
    }[];
    rootClassName?: string;
}

export const BlogPostsCarousel: React.FC<BlogPostsCarouselProps> = ({
    posts, title, subtitle, rootClassName
}) => {
    if (posts.length === 0) {
        return <></>;
    }

    const blockTitle = title || 'Nos articles récents';
    const blockSubtitle = subtitle || '';

    return (
        <div
            className={`${rootClassName ? rootClassName : ''} 
                w-100 d-flex align-items-center justify-content-center pb-5`}>
            <div className="col-11">
                <div className="row w-100 px-0 mx-0  my-3 my-xl-5 justify-content-center">
                    {!isUndOrEmpty(blockTitle) && (
                        <div className="row col-12 justify-content-center text-center">
                            <TitleBlock>{blockTitle}</TitleBlock>
                        </div>
                    )}
                    {!isUndOrEmpty(blockSubtitle) && (
                        <div className="row col-12 justify-content-center text-center">
                            <ReactMarkdown>{blockSubtitle}</ReactMarkdown>
                        </div>
                    )}
                </div>
                <Carousel
                    className={`${styles.carousel} ${posts.length === 1 ? styles.centerItem : ''}`}
                    itemClass={styles.carouselItem}
                    ssr={true}
                    partialVisible={true}
                    responsive={responsive}
                    swipeable
                    infinite
                >
                    {/* <div className={styles.csCard}>
                        <span>Coming soon !</span>
                    </div> */}
                    {posts.map((post, index) => {
                        const props: CardItemProps = {
                            withCtaLink: true,
                            expertise: {
                                title: post.title,
                                description: post.subtitle,
                                picture: post.bannerPicture,
                                ctaText: 'En savoir plus',
                                ctaLink: `${process.env.REACT_APP_BLOG_HOST_URL}/article/${post.slug}`,
                                isExternalLink: true,
                                tags: post.tags.map((t) => t.slug),
                            }
                        };

                        return (
                            <div key={`recent-blog-post-${index}`} className={styles.card}>
                                <CardItem {...props} />
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    );
};
