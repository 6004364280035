import React from 'react';

import { SvgIconProps } from './svg-icon.types';

interface SvgPlusIcon extends SvgIconProps {
    
}

export const SvgPlusIcon: React.FC<SvgIconProps> = ({color}: SvgIconProps) => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            viewBox="0 0 50 50">
                
            <circle fill={color} cx="25" cy="25" r="25" />
            <line
                style={{
                    fill: 'none',
                    stroke: '#FFFFFF',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                x1="25" y1="13" x2="25" y2="38" />
            <line
                style={{
                    fill: 'none',
                    stroke: '#FFFFFF',
                    strokeWidth: 2,
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10
                }}
                x1="37.5" y1="25" x2="12.5" y2="25" />
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    );
};

export default SvgPlusIcon;