import * as React from 'react';

import { motion } from 'framer-motion';
import { Link } from 'gatsby';

import SvgPlusIcon from '../svg-icons/svg-plus-icon.component';
import * as styles from './see-more-button.module.css';
import { SeeMoreButtonProps } from './see-more-button.types';

const BtnContent: React.FC<SeeMoreButtonProps> = ({
    text,
    className = '',
    color,
    ...rest
}) => (
    <div className='d-flex align-items-center'>
        <div className={styles.svgContainer}>
            <SvgPlusIcon color={color || '#000000'} />
        </div>
        <motion.button
            className={`${className} ${styles.btn}`}
            style={{ color }}
            {...rest}
        >
            <span>{text}</span>
        </motion.button>
    </div>
);

export const SeeMoreButton: React.FC<SeeMoreButtonProps> = ({
    link, isBlank = false, ...rest
}) => {
    if (link === undefined || link === null || link === '') {
        return (<BtnContent {...rest} />);
    }

    const isExternalLink = link.startsWith('http') || link.startsWith('www');
    if (isExternalLink) {
        return (
            <a href={link} target={isBlank ? '_blank' : '_parent'} rel='noopener noreferrer'>
                <BtnContent {...rest} />
            </a>
        );
    } else {

        return (
            <Link to={link}>
                <BtnContent {...rest} />
            </Link>
        );
    }
};