import * as React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import Carousel from 'react-multi-carousel';

import { GatsbyStrapiPictureField } from '../../../models/cms-common';
import {
    buildGatsbyStrapiImage,
    isUndOrEmpty
} from '../../../utils';
import { TitleBlock } from '../title-block/title-block.component';
import * as styles from './expertise-certification-block.module.css';

const responsive = {
    md: {
        breakpoint: {
            max: 3000,
            min: 993,
        },
        items: 3,
    },
    sm: {
        breakpoint: {
            max: 992,
            min: 577,
        },
        items: 2,
    },
    xs: {
        breakpoint: {
            max: 576,
            min: 0,
        },
        items: 1,
    },
};

interface ExpertiseCertificationBlockProps {
    id?: string;
    title: string;
    subtitle: string;
    certifications: {
        slug: string;
        CertificationBadge: {
            title: string;
            subtitle: string;
            description: string;
            ctaText: string;
            ctaLink: string;
            picture: GatsbyStrapiPictureField;
        };
    }[];
}

export const ExpertiseCertificationBlock: React.FC<ExpertiseCertificationBlockProps> = ({
    id,
    certifications,
    title,
    subtitle,
}) => {
    return (
        <>
            {certifications.length > 0 && (
                <div {...(id && { id: id })} className="row w-100 px-0 mx-0 my-5 justify-content-center">
                    <div className="row col-12 col-xl-10 my-5">
                        {!isUndOrEmpty(title) && (
                            <div className="row col-12 justify-content-center text-center">
                                <TitleBlock>{title}</TitleBlock>
                            </div>
                        )}

                        {!isUndOrEmpty(subtitle) && (
                            <div className="row col-12 justify-content-center text-center">
                                <ReactMarkdown>{subtitle}</ReactMarkdown>
                            </div>
                        )}
                    </div>

                    <Carousel
                        className={`${styles.carousel} ${certifications.length === 1 ? styles.centerItem: ''}`}
                        itemClass={styles.carouselItem}
                        ssr={true}
                        partialVisible={true}
                        responsive={responsive}
                        autoPlay
                        infinite
                    >
                        {certifications.map((certification, index) => {
                            const image = buildGatsbyStrapiImage(certification.CertificationBadge.picture);
                            return (
                                <div key={`badge-${index}`}>
                                    {image && (
                                        <GatsbyImage
                                            className={styles.badge}
                                            image={image}
                                            alt="badge"
                                            objectFit='contain'
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            )}
        </>
    );
};
