import * as React from 'react';

import {
    StaticQuery,
    graphql
} from 'gatsby';


import { Head as DefaultHead } from '../../components/head/head.component';
import { NosExpertisesBlock } from '../../components/nos-expertises-page/our-values-block/nos-expertises-block.component';
import { BlogPostsCarousel } from '../../components/shared/blog-posts-carousel/blog-posts-carousel.component';
import { CustomSection } from '../../components/shared/custom-section/custom-section.component';
import { ExpertiseCertificationBlock } from '../../components/shared/expertise-certification-block/expertise-certification-block.component';
import { PageTopBlock } from '../../components/shared/page-top-block/page-top-block.component';
import { seo } from '../../config/seo-config';
import {
    BannerProps,
    GatsbyStrapiPictureField
} from '../../models/cms-common';
import { isBrowser } from '../../utils';
import * as styles from './index.module.css';

interface NosExpertisesProps {
    strapiNosExpertises: {
        banner: BannerProps;
        expertiseDomains: {
            title: string;
            description: string;
            picture: GatsbyStrapiPictureField;
            pictureHover: GatsbyStrapiPictureField;
            ctaText: string;
            ctaLink: string;
        }[];

        certifTitle: string;
        certifSubtitle: string;
    };

    allStrapiCertifications: {
        nodes: {
            slug: string;
            CertificationBadge: {
                title: string;
                subtitle: string;
                description: string;
                ctaText: string;
                ctaLink: string;
                picture: GatsbyStrapiPictureField;
            };
        }[];
    };

    allStrapiArticle: {
        nodes: {
            id: string;
            slug: string;
            title: string;
            subtitle: string;
            bannerPicture: GatsbyStrapiPictureField;
            category: {
                id: string;
                slug: string;
            };
            tags: {
                id: string;
                slug: string;
            }[];
        }[];
    };

    ref: React.RefObject<HTMLDivElement>;
}

const nosExpertisesPage: React.FC<NosExpertisesProps> = ({
    strapiNosExpertises,
    allStrapiCertifications,
    allStrapiArticle,
    ref,
}: NosExpertisesProps) => {
    const [transparentMode, setTransparentMode] = React.useState(
        isBrowser() ? document.body.scrollTop < Math.round(window.innerHeight / 2) : false
    );
    const [currentBgColor, setCurrentBgColor] = React.useState<string>('var(--primary-blue)');
    const [darkMode, setDarkMode] = React.useState(true);

    const banner = strapiNosExpertises.banner;
    const expertiseDomains = strapiNosExpertises.expertiseDomains;
    const certifBadges: any[] = allStrapiCertifications.nodes;

    const recentBlogPosts = allStrapiArticle.nodes;

    return (
        <>
            <PageTopBlock
                ref={ref}
                transparent={transparentMode}
                dark={darkMode}
                backgroundColor={currentBgColor}
                setCurrentBgColor={setCurrentBgColor}
                setTransparentMode={setTransparentMode}
                setDarkMode={setDarkMode}
                banner={banner}
                scrollIntoElementId={'nos-expertises'}
                contentBlockAElementId={'certifications'}
                contentBlockBElementId={'nos-valeurs'}
                bannerBackgroundClassName={'NosExpertises'}
            >
                <CustomSection
                    className={`d-flex align-items-center justify-content-center ${styles.blockA}`}
                    onViewportEnter={() => {
                        setDarkMode(false);
                        setCurrentBgColor('var(--white)');
                    }}
                    viewport={{ amount: 0.5 }}
                >
                    <div className="col-11 col-sm-10">
                        <NosExpertisesBlock
                            id="nos-expertises"
                            title={banner.title}
                            subtitle={banner.subtitle}
                            expertiseDomains={expertiseDomains}
                        />
                    </div>
                </CustomSection>

                <div
                    className={`d-flex align-items-center justify-content-center ${styles.blockB}`}>
                    <ExpertiseCertificationBlock
                        id="certifications"
                        certifications={certifBadges}
                        title="Certifications"
                        subtitle="Plusieurs certifications témoignent aujourd'hui de l'expertise de nos consultants."
                    />
                </div>

                {recentBlogPosts && recentBlogPosts.length > 0 && (
                    <div className={`w-100 d-flex align-items-center justify-content-center ${styles.blockC}`}>
                        <BlogPostsCarousel
                            posts={recentBlogPosts}
                        />
                    </div>
                )}
            </PageTopBlock>
        </>
    );
};

const NosExpertisesEnhancer = (Component: React.ComponentType<NosExpertisesProps>) => {
    return (
        <StaticQuery<NosExpertisesProps>
            query={graphql`
                query NosExpertisesQuery {
                    strapiNosExpertises {
                        banner {
                            title
                            subtitle
                        }
                        expertiseDomains {
                            title
                            description
                            picture {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                            pictureHover {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                            ctaLink
                            ctaText
                        }
                    }


                    allStrapiArticle(
                        limit: 10
                        sort: { order: DESC, fields: published_at }
                    ) {
                        nodes {
                            id
                            slug
                            title
                            author
                            subtitle
                            bannerPicture {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                            category {
                                id
                                slug
                                color
                            }
                            tags {
                                id
                                slug
                            }
                        }
                    }

                    allStrapiCertifications(limit: 10, sort: { order: DESC, fields: published_at }) {
                        nodes {
                            slug
                            CertificationBadge {
                                title
                                subtitle
                                description
                                ctaText
                                ctaLink
                                picture {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => <Component {...data} />}
        />
    );
};

function NosExpertisesPage() {
    return NosExpertisesEnhancer(nosExpertisesPage);
}

export default NosExpertisesPage;

export const Head = () => <DefaultHead {...seo.nosExpertises} />;
