/* eslint-disable no-unused-vars */
import * as React from 'react';

import {
    motion,
    useViewportScroll
} from 'framer-motion';

import { BannerProps } from '../../../models/cms-common';
import {
    HEADER_HEIGHT_DESKTOP,
    HEADER_HEIGHT_MOBILE
} from '../../../styles/global-style-constants';
import { isBrowser } from '../../../utils';
import { BannerBlock } from '../banner-block/banner-block.component';
import { CustomHeader } from '../custom-header/custom-header.component';
import * as styles from './page-top-block.module.css';

export interface PageTopBlockProps {
    transparent: boolean;
    dark: boolean;
    backgroundColor: string;

    ref: React.RefObject<HTMLDivElement>;

    setCurrentBgColor: (s: string) => any;
    setTransparentMode: (b: boolean) => any;
    setDarkMode: (b: boolean) => any;

    scrollIntoElementId: string;

    contentBlockAElementId?: string,
    contentBlockBElementId?: string,

    banner: BannerProps;
    bannerBackgroundClassName?: string;
}

export const PageTopBlock: React.FC<PageTopBlockProps> = ({
    transparent, dark, backgroundColor = '#fbfcfa',
    ref,

    setCurrentBgColor,
    setTransparentMode,
    setDarkMode,

    scrollIntoElementId,
    contentBlockAElementId,
    contentBlockBElementId,

    banner,
    bannerBackgroundClassName,
    children
}) => {
    const { scrollY } = useViewportScroll();

    React.useEffect(() => {
        if (isBrowser()) {
            scrollY.onChange((val) => {
                let contentBlockA: HTMLElement | null = null;
                let contentBlockB: HTMLElement | null = null;
                if (contentBlockAElementId && contentBlockBElementId) {
                    contentBlockA = document.getElementById(contentBlockAElementId);
                    contentBlockB = document.getElementById(contentBlockBElementId);
                }

                if (contentBlockA !== null && contentBlockB !== null) {
                    const headerHeight = window.innerWidth > 480 ? HEADER_HEIGHT_DESKTOP : HEADER_HEIGHT_MOBILE;
                    const intervalB = contentBlockB.offsetTop - (headerHeight);

                    const pageRoot = ref?.current;
                    if (pageRoot) {
                        pageRoot.style.backgroundColor = 'var(--white)';
                        if (val > intervalB) {
                            setDarkMode(true);
                        }
                    }
                }
            });
        }

        return () => scrollY.clearListeners();
    }, [scrollY, ref]);

    const onArrowClick = () => {
        if (isBrowser()) {
            const section = document.getElementById(scrollIntoElementId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <>
            <CustomHeader transparent={transparent} dark={dark} backgroundColor={backgroundColor} />

            <div ref={ref} className={styles.page}>
                <motion.section
                    className={styles.bannerBlockContainer}
                    onViewportEnter={() => {
                        setTransparentMode(true);
                        setDarkMode(true);
                        setCurrentBgColor('var(--primary-blue)');
                    }}
                    onViewportLeave={() => {
                        setTransparentMode(false);
                    }}
                    viewport={{ amount: 0.75 }}
                >
                    <BannerBlock
                        title={banner.title || ''}
                        subtitle={banner.subtitle || ''}
                        backgroundClassName={bannerBackgroundClassName || ''}
                        onScrollIconClick={onArrowClick}
                    />
                </motion.section>

                {children}
            </div>
        </>
    );
};
